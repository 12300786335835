// Dalam file src/components/ResultsPage.js

import React from 'react';
import { useLocation } from 'react-router-dom'; // Jika mengirim hasil melalui state navigasi
import { Card, ListGroup, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ResultsPage = () => {
    const location = useLocation();
    const scores = location.state?.results; // Asumsikan hasil di-pass melalui state navigasi
    const { results, totalQuestions } = location.state || {};
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/');
    };
    // Kemudian gunakan totalQuestions untuk menghitung persentase


    if (!scores) {
        return <div className="text-center mt-5">Tidak ada hasil untuk ditampilkan.</div>;
    }

    // Fungsi untuk menghitung persentase
    const calculatePercentage = (score) => {
        return (score / (totalQuestions - 1)) * 100;
    };

    // Menemukan paslon dengan skor tertinggi
    const maxScorePaslon = Object.entries(scores).reduce((maxPaslon, currentPaslon) => {
        const [, currentScore] = currentPaslon;
        const [, maxScore] = maxPaslon;
        return currentScore > maxScore ? currentPaslon : maxPaslon;
    }, ['', 0])[0];

    const sortedScores = Object.entries(scores)
        .sort((a, b) => calculatePercentage(b[1]) - calculatePercentage(a[1]));


    // Render hasil
    return (
        <div className="items-center flex max-w-screen-sm flex-col h-screen mx-auto px-8">
            <img
                loading="lazy"
                srcSet="https://i.ibb.co/q1Pdk00/DALL-E-2024-01-28-10-16-43-An-illustration-showcasing-the-celebration-of-an-election-victory-in-Indo.png"
                className="aspect-[2] object-cover object-center w-full shrink-0 box-border min-h-[20px] min-w-[20px] overflow-hidden max-w-screen-md mx-auto"
            />
            <div className="text-center w-full mt-2">Calon ideal mu adalah </div>
            <div className="text-center text-md font-bold w-full mt-2">
                🎉 <strong>{maxScorePaslon}</strong> 🎉
            </div>
            <div className="text-center w-full mt-2">
                Gunakan informasi ini sebagai langkah awal dalam mengeksplorasi lebih
                lanjut tentang masing-masing calon. Pilihan Anda memiliki dampak yang
                signifikan - pilihlah dengan bijak!
            </div>
            <div className="flex flex-col items-stretch pt-8">
                <div className="w-full text-center mt-[px]">Klasemen kecocokan :</div>
                <div className="flex flex-col items-stretch">
                    {sortedScores.map(([paslon, score], index) => (
                        <div key={index} className="w-full text-center mt-2">
                            <strong>{paslon}</strong> (<span className="score">{calculatePercentage(score).toFixed(2)}%)</span>
                        </div>

                    ))}
                </div>
            </div>
            <button onClick={navigateToHome} className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 my-4 rounded-full  w-full">
                Ke Beranda
            </button>
        </div>

    );
};

export default ResultsPage;
