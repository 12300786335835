// Dalam file src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { Link } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import HomePage from './components/HomePage'; // Asumsikan komponen ini ada dan telah dibuat
import QuizPage from './components/QuizPage'; // Asumsikan komponen ini ada dan telah dibuat
import ResultsPage from './components/ResultsPage'; // Asumsikan komponen ini ada dan telah dibuat
import NavbarComponent from './components/NavbarComponent';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import './App.css'; // Custom CSS Anda

function App() {
  return (
    <Router>
      <title>PemilihPintar</title>
      <header className="text-black text-s font-bold text-center mx-auto py-4 no-underline text-blue-600">
        <a href='/' className='text-black no-underline hover:text-sky-400 text-blue-600'>PemilihPintar.site</a>
      </header>
      <Analytics />
      <SpeedInsights />
      <div className="min-h-full">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/quiz/:category" element={<QuizPage />} />
          <Route path="/results" element={<ResultsPage />} />
        </Routes>
      </div>
      <footer className="justify-between items-stretch self-stretch flex gap-5 text-xs text-black font-light whitespace-nowrap text-center max-w-[720px] mx-auto px-8 pt-32 pb-4">
        <Link className="no-underline text-slate-400 hover:text-sky-400">Penafian</Link>
        <Link className="no-underline text-slate-400 hover:text-sky-400">Tentang</Link>
        <Link className="no-underline text-slate-400 hover:text-sky-400">Umpan Balik</Link>
      </footer>
    </Router>
  );
}

export default App;
