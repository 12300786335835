import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();

    const handleFindIdealCandidate = () => {
        // Logika untuk navigasi, misalnya ke halaman quiz
        navigate('/quiz');
    };

    return (

        <div className="flex flex-col relative shrink-0 box-border max-w-screen-md m-auto max-sm:max-w-[300px]">
            <div className="flex flex-col relative shrink-0 box-border my-auto">
                <div className="flex flex-col relative shrink-0 box-border">
                    <div className="flex flex-col relative shrink-0 box-border">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11?width=100 100w, https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11?width=200 200w, https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11?width=400 400w, https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11?width=800 800w, https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11?width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11?width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11?width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2F05261bf655fb441688c6780ce04b1039%2F0b8c82eac3c34c17abf69804ea6cde11"
                            className="aspect-[2] object-cover object-center w-full shrink-0 box-border min-h-[20px] min-w-[20px] overflow-hidden max-w-screen-md mx-auto"
                        />
                        <div className="relative shrink-0 box-border h-auto text-center tracking-[8px] text-xs mt-5">
                            <strong>
                                <font color="#4a4a4a">PEMILIH PINTAR</font>
                            </strong>
                        </div>
                        <div className="relative shrink-0 box-border h-auto text-2xl font-semibold mt-2 mx-auto max-sm:text-2xl text-center">
                            Temukan Pemimpin Ideal Anda dengan Menggunakan AI!
                        </div>
                        <div className="relative shrink-0 box-border h-auto mt-2 mx-auto max-sm:text-center max-sm:text-base">
                            Pilihan anda menentukan masa depan bangsa.
                        </div>
                    </div>
                    <div className="flex flex-col relative shrink-0 box-border mt-7">
                        <Button
                            className="relative shrink-0 box-border appearance-none bg-blue-500 text-[white] text-center cursor-pointer px-4 py-2 rounded-[64px] no-underline"
                            openLinkInNewTab={false}
                            href="#list-quiz"
                        >
                            Coba Kuis, Gratis!
                        </Button>
                        <div className="relative shrink-0 box-border h-auto text-center text-base mt-2 max-sm:text-xs ">
                            <font color="#9b9b9b">Sekarang atau lima tahun lagi..</font>
                        </div>
                    </div>
                    <div className="flex flex-col relative shrink-0 box-border h-auto mt-5 pb-8 max-sm:mt-2" id="list-quiz">
                        <div className="flex flex-col relative shrink-0 box-border">
                            <div className="relative shrink-0 box-border h-auto text-center text-2xl font-semibold mt-5">
                                Daftar Kuis
                            </div>
                            <div className="relative shrink-0 box-border h-auto text-center mt-3">
                                Coba kuis terkait Pemimpin Ideal Anda!
                            </div>
                        </div>
                        <div className="flex flex-col relative shrink-0 box-border mt-3">
                            <div className="gap-3 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                                    <div className="flex flex-col relative shrink-0 box-border border px-6 py-4 rounded-xl border-solid max-sm:mt-2">
                                        <div className="flex flex-col relative shrink-0 box-border">
                                            <div className="relative shrink-0 box-border h-auto font-semibold text-base">
                                                Quiz PemilihPintar (Singkat)
                                            </div>
                                            <div className="relative shrink-0 box-border h-auto text-xs pt-1">
                                                Kuis singkat yang dibuat berdasarkan Program Kerja Capres dengan AI Pemilih Pintar. Estimasi pengerjaan 5-10 Menit.
                                            </div>
                                        </div>
                                        <Link
                                            className="relative shrink-0 box-border appearance-none bg-blue-500 bg-opacity-0 text-blue-500 text-center cursor-pointer border text-xs mr-auto mt-3 px-4 py-2 rounded-[64px] border-solid border-blue-500 nounderline"
                                            openLinkInNewTab={false} to="/quiz/umum2"
                                        >
                                            Coba Kuis
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                                    <div className="flex flex-col relative shrink-0 box-border border px-6 py-4 rounded-xl border-solid max-sm:mt-2">
                                        <div className="flex flex-col relative shrink-0 box-border">
                                            <div className="relative shrink-0 box-border h-auto font-semibold text-base">
                                                Quiz Isu & Gagasan BijakMemilih
                                            </div>
                                            <div className="relative shrink-0 box-border h-auto text-xs pt-1">
                                                Berdasarkan 34 Isu & Gagasan yang dirangkum oleh <a href='https://www.bijakmemilih.id/en/komparasi-gagasan' target='_blank'>BijakMemilih</a>.  Estimasi pengerjaan 5-10 Menit.
                                            </div>
                                        </div>
                                        <Link
                                            className="relative shrink-0 box-border appearance-none bg-blue-500 bg-opacity-0 text-blue-500 text-center cursor-pointer border text-xs mr-auto mt-3 px-4 py-2 rounded-[64px] border-solid border-blue-500 nounderline"
                                            openLinkInNewTab={false} to="/quiz/isubm"
                                        >
                                            Coba Kuis
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col relative shrink-0 box-border mt-3">
                            <div className="gap-3 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                                    <div className="flex flex-col relative shrink-0 box-border border px-6 py-4 rounded-xl border-solid max-sm:mt-2">
                                        <div className="flex flex-col relative shrink-0 box-border">
                                            <div className="relative shrink-0 box-border h-auto font-semibold text-base">
                                                Quiz PemilihPintar (Umum)
                                            </div>
                                            <div className="relative shrink-0 box-border h-auto text-xs pt-1">
                                                Kuis yang dibuat berdasarkan Program Kerja Capres dengan AI Pemilih Pintar. Estimasi pengerjaan 10-20 Menit.
                                            </div>
                                        </div>
                                        <Link
                                            className="relative shrink-0 box-border appearance-none bg-blue-500 bg-opacity-0 text-blue-500 text-center cursor-pointer border text-xs mr-auto mt-3 px-4 py-2 rounded-[64px] border-solid border-blue-500 nounderline"
                                            openLinkInNewTab={false} to="/quiz/umum"
                                        >
                                            Coba Kuis
                                        </Link>
                                    </div>
                                </div>
                                {/* <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                                    <div className="flex flex-col relative shrink-0 box-border border px-6 py-4 rounded-xl border-solid max-sm:mt-2">
                                        <div className="flex flex-col relative shrink-0 box-border">
                                            <div className="relative shrink-0 box-border h-auto font-semibold text-base">
                                                Quiz Komparasi Isu & Gagasan BijakMemilih
                                            </div>
                                            <div className="relative shrink-0 box-border h-auto text-xs pt-1">
                                                Berdasarkan 34 Isu & Gagasan yang dirangkum oleh <a href='https://www.bijakmemilih.id/en/komparasi-gagasan' target='_blank'>BijakMemilih</a>
                                            </div>
                                        </div>
                                        <Link
                                            className="relative shrink-0 box-border appearance-none bg-blue-500 bg-opacity-0 text-blue-500 text-center cursor-pointer border text-xs mr-auto mt-3 px-4 py-2 rounded-[64px] border-solid border-blue-500 nounderline"
                                            openLinkInNewTab={false} to="/quiz/isubm"
                                        >
                                            Coba Kuis
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default HomePage;
