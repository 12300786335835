// Dalam file src/components/QuizPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import questionsData from '../data/questions.json'; // Pastikan lokasi file ini benar
import DisclaimerModal from './DisclaimerModal';

const QuizPage = () => {
    const { category } = useParams();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [userAnswers, setUserAnswers] = useState([]);
    const [scores, setScores] = useState({});
    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [disclaimerAgreed, setDisclaimerAgreed] = useState(false);

    useEffect(() => {
        if (questions.length > 0) {
            const currentQuestion = questions[currentQuestionIndex];
            setShuffledOptions(shuffleArray([...currentQuestion.options]));
        }
    }, [questions, currentQuestionIndex]);

    useEffect(() => {
        // Filter pertanyaan berdasarkan kategori yang dipilih
        const filteredCategory = questionsData.find(q => q.category.toLowerCase() === category.toLowerCase());
        if (filteredCategory && filteredCategory.questions) {
            setQuestions(filteredCategory.questions);
        }
        setLoading(false);
    }, [category]);

    const handleOptionSelect = (option) => {
        // Update skor berdasarkan paslon
        setScores(prevScores => ({
            ...prevScores,
            [option.paslon]: (prevScores[option.paslon] || 0) + 1
        }));
        // Logika untuk mengelola jawaban (opsional)
        const nextIndex = currentQuestionIndex + 1;
        const results = { /* objek hasil Anda */ };
        setUserAnswers(prevAnswers => [...prevAnswers, option]);

        if (nextIndex < questions.length) {
            setCurrentQuestionIndex(nextIndex);
        } else {
            // Siapkan objek hasil berdasarkan jawaban pengguna
            const results = calculateResults(userAnswers);
            navigate('/results', {
                state: {
                    results: scores,
                    totalQuestions: questions.length // Kirim jumlah total pertanyaan
                }
            });
        }
    };

    const calculateResults = (answers) => {
        // Implementasikan logika untuk menghitung hasil
        // Misalnya, menghitung skor atau mengelompokkan jawaban berdasarkan kategori
        // Contoh sederhana: menghitung jumlah jawaban benar
        const score = answers.filter(answer => answer.isCorrect).length;
        return { score };
    };

    if (loading) {
        return <div className="text-center mt-5">Memuat soal...</div>;
    }

    if (!questions.length) {
        return <div className="text-center mt-5">Tidak ada soal untuk kategori ini.</div>;
    }

    const currentQuestion = questions[currentQuestionIndex];

    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }


    return (
        <div className="min-h-full">
            {!disclaimerAgreed && (
                <DisclaimerModal
                    show={!disclaimerAgreed}
                    onAgree={() => setDisclaimerAgreed(true)}
                />
            )}
            {disclaimerAgreed && (
                <div>
                    <div className="justify-center items-stretch flex max-w-[328px] flex-col w-full mx-auto ">
                        <div className="text-black text-center text-xs w-full">
                            {currentQuestionIndex + 1} dari {questions.length} Pertanyaan
                        </div>
                        <div className="text-black text-center text-lg font-bold w-full mt-2">
                            {currentQuestion.text}
                        </div>
                        <p className='justify-center text-center items-stretch flex max-w-[328px] text-xs flex-col w-full mx-auto '>
                            Pilih yang paling sesuai.
                        </p>
                        {shuffledOptions.map((option, index) => (
                            <div className="flex flex-col">
                                 <button
                                    key={index}
                                    className="text-black text-center text-s justify-center items-stretch border bg-white mt-3 px-8 py-16  rounded-2xl border-solid border-zinc-400 max-sm:text-xs"
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    {option.text}
                                </button>
                            </div>
                        ))}
                    </div>
                    
                </div>
            )}
        </div>

    );
};

export default QuizPage;
